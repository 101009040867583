import React, { useState, useContext } from 'react';
import { Editor } from '@tinymce/tinymce-react';

// Context
import { SettingsContext } from '../reducer';

export default (
    {
        labelText,
        placeholder,
        iconClass,
        onChange,
        settingsName,
        controlledValue,
        enableEditor = false,
        type = "text",
        hasIcon = false,
        iconColor = '#dbdbdb',
        hasAddOns = false,
        addOnsText = ""
    }
) => {

    const [value, setValue] = useState('');
    const dispatch = useContext(SettingsContext);

    const handleChange = evt => {
        const value = type === 'checkbox' ? evt.target.checked : evt.target.value;

        setValue(value);

        if (onChange) {
            onChange(value)
        }
    };

    const handleEditorChange = (content, editor) => {
        dispatch({ type: 'update', payload: { name: settingsName, value: content } });
    };

    return (
        <div className="field">
            <label className="label">
                {
                    enableEditor ?
                        <Editor
                            apiKey="bwwknds0v969zn0zafqxgwusfwpc0wfbqfr0apqjhymrodtv"
                            initialValue={labelText}
                            value={controlledValue || labelText}
                            inline={true}
                            onEditorChange={handleEditorChange}
                            init={{
                                toolbar: false,
                                menubar: false
                            }}
                        />
                        : labelText
                }
            </label>
            <div className={`field ${hasAddOns ? 'has-addons' : ''}`}>
                {
                    hasAddOns &&
                    <div className="control">
                        <span className="button is-medium is-static">
                            {addOnsText}
                        </span>
                    </div>
                }
                <div className={`control ${hasIcon ? 'has-icons-left' : ''} is-expanded`}>
                    {
                        type === 'checkbox' ?
                            <label className="checkbox">
                                <input type="checkbox" onChange={handleChange} />
                                &nbsp;&nbsp;{placeholder}
                            </label>
                            :
                            <input className="input is-medium" type={type} placeholder={placeholder} value={value} onChange={handleChange} />
                    }
                    {
                        hasIcon &&
                        <span className="icon is-small is-left" style={{ color: iconColor }}>
                            <i className={`fas ${iconClass}`}></i>
                        </span>
                    }
                </div>
            </div>
        </div>
    );
};