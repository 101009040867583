import React, { useState, useRef } from 'react';

// Utils
import { serialiseFileUpload } from '../utils/utils';

export default ({ onDismiss, onSave }) => {

    const [value, setValue] = useState('');
    const fileRef = useRef(null);

    const handleConfigChange = evt => {
        if (evt.target && evt.target.files && evt.target.files.length) {
            const file = evt.target.files[0];

            serialiseFileUpload(file, false)
                .then(encodedFile => {
                    try {
                        setValue(encodedFile);
                    } catch (err) { }
                });
        }
    };

    return (
        <div className="modal-container">
            <div className="box">
                <nav className="level">
                    <div className="level-left">
                        <div className="level-item">
                            <h2 className="subtitle is-size-4">Paste your config below</h2>
                        </div>
                    </div>
                    <div className="level-right">
                        <div className="level-item">
                            <input type="file" className="is-hidden" ref={fileRef} onChange={handleConfigChange} />
                            <button
                                className="button is-pulled-right is-info"
                                onClick={() => fileRef.current.click()}
                            >
                                load from file
                            </button>
                        </div>
                    </div>
                </nav>
                <hr />
                <textarea
                    className="textarea"
                    placeholder="...paste your config here"
                    rows="15"
                    value={value}
                    onChange={e => setValue(e.target.value)}
                >
                </textarea>
                <hr />
                <nav className="level">
                    <div className="level-left">
                        <div className="level-item">
                            <button className="button is-text" onClick={onDismiss}>
                                cancel
                            </button>
                        </div>
                    </div>
                    <div className="level-right">
                        <div className="level-item">
                            <button className="button is-primary" onClick={() => onSave(value)}>
                                save
                            </button>
                        </div>
                    </div>
                </nav>
            </div>
        </div>
    );
};
