import React, { useContext } from 'react';
import { Editor } from '@tinymce/tinymce-react';

// Context
import { SettingsContext, StateContext } from '../reducer';

// Components
import FormInput from './FormInput';

// Constants
import { SETTINGS } from '../constants';

export default props => {

    const dispatch = useContext(SettingsContext);
    const appState = useContext(StateContext);

    return (
        <div className="login-form-container">
            <div className="login-form">
                <header style={{ backgroundColor: appState[SETTINGS.LOGIN_HEADER_BACKGROUND_COLOR] }}>
                    <div className="subtitle is-size-3">
                        <Editor
                            apiKey="bwwknds0v969zn0zafqxgwusfwpc0wfbqfr0apqjhymrodtv"
                            initialValue={appState[SETTINGS.LOGIN_HEADER_TEXT]}
                            value={appState[SETTINGS.LOGIN_HEADER_TEXT]}
                            inline={true}
                            onEditorChange={(content, editor) => dispatch({ type: 'update', payload: { name: SETTINGS.LOGIN_HEADER_TEXT, value: content } })}
                            init={{
                                toolbar: false,
                                menubar: false
                            }}
                        />
                    </div>
                </header>

                <form>
                    <FormInput
                        labelText={appState[SETTINGS.LOGIN_USERNAME_LABEL]}
                        controlledValue={appState[SETTINGS.LOGIN_USERNAME_LABEL]}
                        hasIcon={true}
                        iconColor={appState[SETTINGS.LOGIN_BRAND_COLOR]}
                        placeholder="Email Address"
                        iconClass="fa-envelope"
                        settingsName={SETTINGS.LOGIN_USERNAME_LABEL}
                        enableEditor={true}
                    />
                    <FormInput
                        labelText={appState[SETTINGS.LOGIN_PASSWORD_LABEL]}
                        controlledValue={appState[SETTINGS.LOGIN_PASSWORD_LABEL]}
                        hasIcon={true}
                        iconColor={appState[SETTINGS.LOGIN_BRAND_COLOR]}
                        placeholder="Password"
                        iconClass="fa-lock"
                        settingsName={SETTINGS.LOGIN_PASSWORD_LABEL}
                        enableEditor={true}
                    />
                    <div className="field">
                        <div className="control">
                            <button
                                className="button is-medium is-fullwidth"
                                style={{
                                    backgroundColor: appState[SETTINGS.LOGIN_BRAND_COLOR]
                                }}
                                onClick={e => e.preventDefault()}
                            >
                                <Editor
                                    apiKey="bwwknds0v969zn0zafqxgwusfwpc0wfbqfr0apqjhymrodtv"
                                    initialValue={appState[SETTINGS.LOGIN_BUTTON_TEXT]}
                                    value={appState[SETTINGS.LOGIN_BUTTON_TEXT]}
                                    inline={true}
                                    onEditorChange={(content, editor) => dispatch({ type: 'update', payload: { name: SETTINGS.LOGIN_BUTTON_TEXT, value: content } })}
                                    init={{
                                        toolbar: false,
                                        menubar: false
                                    }}
                                />
                            </button>
                        </div>
                    </div>
                </form>

                <footer>
                    Powered By <img src={require('../assets/img/IAMCloud-FullColour-transparent.png')} alt="full color logo" />
                </footer>
            </div>
        </div>
    );
};