import React, { useContext } from 'react';
import { Editor } from '@tinymce/tinymce-react';

// Context
import { SettingsContext, StateContext } from '../reducer';

// Components
import ImageEditor from './ImageEditor';

// Constants
import { SETTINGS } from '../constants';

export default props => {

    const dispatch = useContext(SettingsContext);
    const appState = useContext(StateContext);

    const handleEditorChange = (content, editor) => {
        dispatch(
            {
                type: 'update',
                payload: {
                    name: editor.id === 'edit_title' ? SETTINGS.WELCOME_TEXT_TITLE : SETTINGS.WELCOME_TEXT_CONTENT,
                    value: content
                }
            }
        );
    };

    return (
        <div className="content is-medium intro-container">
            <div className="intro-img">
                <ImageEditor
                    defaultImagePath={appState[SETTINGS.MAIN_LOGO]}
                    onChange={encodedFile => dispatch({ type: 'update', payload: { name: SETTINGS.MAIN_LOGO, value: encodedFile } })}
                    defaultImageAlign={appState[SETTINGS.MAIN_LOGO_ALIGN]}
                    onImageAlign={alignment => dispatch({ type: 'update', payload: { name: SETTINGS.MAIN_LOGO_ALIGN, value: alignment } })}
                />
            </div>
            <div className="intro-content">
                <h1 className="subtitle is-size-2" style={{ color: appState[SETTINGS.WELCOME_TEXT_COLOUR] }}>
                    <Editor
                        id="edit_title"
                        apiKey="bwwknds0v969zn0zafqxgwusfwpc0wfbqfr0apqjhymrodtv"
                        initialValue={appState[SETTINGS.WELCOME_TEXT_TITLE]}
                        value={appState[SETTINGS.WELCOME_TEXT_TITLE]}
                        inline={true}
                        onEditorChange={handleEditorChange}
                        toolbar="fontselect | undo redo | bold italic | alignleft aligncenter alignright | fontsizeselect"
                        init={{
                            toolbar: false,
                            menubar: false,
                            fontsize_formats: '11px 12px 14px 16px 18px 24px 36px 48px 50px 56px 62px 70px'
                        }}
                    />
                </h1>
                <div style={{ color: appState[SETTINGS.WELCOME_TEXT_COLOUR] }}>
                    <Editor
                        id="edit_intro"
                        apiKey="bwwknds0v969zn0zafqxgwusfwpc0wfbqfr0apqjhymrodtv"
                        initialValue={appState[SETTINGS.WELCOME_TEXT_CONTENT]}
                        value={appState[SETTINGS.WELCOME_TEXT_CONTENT]}
                        inline={true}
                        onEditorChange={handleEditorChange}
                        plugins="link lists"
                        toolbar="fontselect | undo redo | bold italic | alignleft aligncenter alignright | fontsizeselect | bullist numlist outdent indent | link"
                        init={{
                            menubar: false,
                            fontsize_formats: '11px 12px 14px 16px 18px 24px 36px 48px 50px 56px 62px 70px'
                        }}
                    />
                </div>
            </div>
        </div>
    );
};