export const SETTINGS = {
    TENANCYID: 'tenancy_id',
    USERNAME: 'username',
    LOGIN_HEADER_TEXT: 'form_header_text',
    LOGIN_HEADER_BACKGROUND_COLOR: 'form_backgroundColor',
    LOGIN_USERNAME_LABEL: 'form_username_label',
    LOGIN_PASSWORD_LABEL: 'form_password_label',
    LOGIN_BUTTON_TEXT: 'form_button_text',
    LOGIN_BRAND_COLOR: 'form_brandColor',
    BACKGROUND_COLOR: 'layout_backgroundColor',
    BACKGROUND_IMAGE: 'layout_backgroundImage',
    BACKGROUND_COVER: 'layout_backgroundSize',
    MAIN_LOGO: 'logo',
    MAIN_LOGO_ALIGN: 'logo_image_alignment',
    WELCOME_TEXT_TITLE: 'intro_title',
    WELCOME_TEXT_CONTENT: 'intro_content',
    WELCOME_TEXT_COLOUR: 'intro_color',
};

export const STORAGE_KEY = 'config_file';