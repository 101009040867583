import React from 'react';

export default ({ isActive }) => {

    return (
        <section className={`hero is-success is-fullheight ${isActive ? 'is-active' : ''}`}>
            <div className="hero-body">
                <div className="container has-text-centered">
                    <div className="content">
                        <div className="icon" style={{ fontSize: '10rem', marginBottom: '2rem' }}>
                            <i className="fas fa-check-circle" />
                        </div>
                        <h1 className="title is-size-1">
                            Success! Your settings have been sent
                        </h1>
                        <p className="subtitle is-size-3">
                            We'll receive an email soon with your settings. <br />Once they're applied, we'll be in touch to show off
                            your organisation's new login screen in action.
                        </p>
                    </div>
                </div>
            </div>
        </section>
    )
};