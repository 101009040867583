import React, { useState, useContext } from 'react';

// Context
import { SettingsContext } from '../reducer';

// Utils
import { serialiseFileUpload } from '../utils/utils';

export default ({ labelText, onChange, settingsName, showClearButton, fileSizeLimit, errorMessage }) => {

    const [fileName, setFileName] = useState('');
    const [showError, setShowError] = useState(false);
    const dispatch = useContext(SettingsContext);

    const handleChange = evt => {
        setShowError(false);

        if (evt.target && evt.target.files && evt.target.files.length) {
            const file = evt.target.files[0];
            setFileName(file.name);

            const fileSizeInKb = file.size / 1024;

            if (fileSizeInKb > fileSizeLimit) {
                setShowError(true);
                return;
            }

            serialiseFileUpload(file)
                .then(encodedFile => {
                    dispatch({ type: 'update', payload: { name: settingsName, value: encodedFile } });
                });
        }


        if (onChange) {
            onChange(evt.target.files[0]);
        }
    };

    const handleRemoveClick = () => {
        setShowError(false);
        setFileName('');
        dispatch({ type: 'delete', payload: { name: settingsName } });
    };

    return (
        <div className="field">
            <label className="label">
                {labelText}
            </label>
            <div className="file is-medium is-fullwidth has-name">
                <label className="file-label">
                    <input
                        className="file-input"
                        type="file"
                        name="fileUpload"
                        accept="image/*"
                        onChange={handleChange}
                    />
                    <span className="file-cta">
                        <span className="file-icon">
                            <i className="fas fa-upload"></i>
                        </span>
                        <span className="file-label">
                            Choose a file…
                        </span>
                    </span>
                    <span className="file-name">
                        {fileName || 'No file uploaded'}
                    </span>
                </label>
            </div>
            {
                showError &&
                <p className="help">{errorMessage}</p>
            }
            {
                showClearButton && fileName &&
                <p>
                    <button
                        className="button is-small is-rounded is-danger"
                        onClick={handleRemoveClick}
                        style={{ marginTop: '1rem' }}
                    >
                        clear
                    </button>
                </p>
            }
        </div>
    );
};