import React from 'react';


export default ({ onClick, visible }) => {

    return (
        <div className={`save-prompt ${visible ? 'is-visible' : ''}`}>
            You have unsaved changes to your work. Would you like to save them?&nbsp;&nbsp;&nbsp;
            <button className="button is-rounded is-primary" onClick={onClick}>save changes</button>
        </div>
    );
};