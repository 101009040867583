import React, { useState, useEffect, useReducer } from 'react';
import { toast } from 'react-toastify';

// Reducer
import { SettingsContext, initialState, reducer, StateContext } from './reducer';

// Helpers
import { storageUtils, saveFile, sendMail } from './utils/utils';

// Styles
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'react-toastify/dist/ReactToastify.min.css';
import './scss/styles.scss';

// Components
import EditorPanel from './containers/EditorPanel';
import Welcome from './components/Welcome';
import LoginForm from './components/LoginForm';
import UsernameInput from './components/UsernameInput';
import SaveBanner from './components/SaveBanner';
import ImportConfigModal from './components/ImportConfigModal';
import MailSentMessage from './components/MailSentMessage';

// Constants
import { SETTINGS, STORAGE_KEY } from './constants';


export default props => {

  // state
  const [initialLoadComplete, setInitialLoadComplete] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [showImportModal, setShowImportModal] = useState(false);
  const [usernameSet, setUsernameSet] = useState(false);
  const [showSavePrompt, setShowSavePrompt] = useState(false);
  const [layoutStyles, setLayoutStyles] = useState({});
  const [showMailSentMessage, setShowMailSentMessage] = useState(false);
  const [state, dispatch] = useReducer(reducer, initialState);

  // variables
  const _userName = state[SETTINGS.USERNAME];


  const handleSaveClick = () => {
    setIsSaving(true);
    storageUtils.saveConfig(STORAGE_KEY, state);

    // Attempt to save to file
    try {
      saveFile(JSON.stringify(state), `${state[SETTINGS.USERNAME]}_config.json`, 'text/plain');
    } catch (err) {

    }

    setTimeout(() => {
      setIsSaving(false);
      toast('Settings saved successfull!', { type: 'success' });
    }, 600);

    setShowSavePrompt(false);
  };

  const handleSaveImportClick = config => {
    try {
      const parsedConfig = JSON.parse(config);
      dispatch({ type: 'init', payload: { ...parsedConfig } });
      setShowImportModal(false);
    } catch (err) {
      // #TODO - error handling here
    }
  };

  const handleResetClick = () => {
    storageUtils.deleteConfig(STORAGE_KEY);
    dispatch({ type: 'reset' });
    setUsernameSet(false);
    setInitialLoadComplete(false);
  }

  const handleSendClick = () => {
    sendMail(
      `SSGUI Settings for tenancy, ${state[SETTINGS.USERNAME]}`,
      JSON.stringify(state, null, 2),
      () => {
        setShowMailSentMessage(true);
        setTimeout(() => { setShowMailSentMessage(false) }, 4000);
      }
    );
  };

  useEffect(() => {
    const layoutStylesCopy = { ...layoutStyles };

    layoutStylesCopy.backgroundColor = state[SETTINGS.BACKGROUND_COLOR];
    layoutStylesCopy.backgroundImage = `url(${state[SETTINGS.BACKGROUND_IMAGE]})`;
    layoutStylesCopy.backgroundPosition = 'top center';
    layoutStylesCopy.backgroundRepeat = 'no-repeat';
    layoutStylesCopy.backgroundSize = state[SETTINGS.BACKGROUND_COVER];

    setLayoutStyles(layoutStylesCopy);

    if (initialLoadComplete) {
      setShowSavePrompt(true);
    }
    // eslint-disable-next-line
  }, [state]);


  useEffect(() => {
    if (_userName && usernameSet) {
      setUsernameSet(true);
      setInitialLoadComplete(true);
    }
  }, [_userName, usernameSet]);

  useEffect(() => {
    // load up any existing config
    const savedConfig = storageUtils.loadConfig(STORAGE_KEY);

    if (savedConfig && Object.keys(savedConfig).length) {
      dispatch({ type: 'init', payload: { ...savedConfig } });
      setUsernameSet(savedConfig[SETTINGS.USERNAME] ? true : false);
    }
  }, []);

  return (
    <SettingsContext.Provider value={dispatch}>
      <StateContext.Provider value={state}>
        <main className="main-layout" style={layoutStyles}>
          {
            usernameSet &&
            <EditorPanel
              title={state[SETTINGS.USERNAME]}
              onSaveClick={handleSaveClick}
              onImportClick={() => setShowImportModal(true)}
              onSendClick={handleSendClick}
              onResetClick={handleResetClick}
              isSaving={isSaving}
            />
          }
          <div className="container">
            <div className="columns">
              {
                !usernameSet &&
                <UsernameInput onSaveUsernameClick={() => setUsernameSet(true)} />
              }
              {
                usernameSet &&
                <>
                  <div className="column is-6 is-offset-1">
                    <Welcome />
                  </div>
                  <div className="column is-5">
                    <LoginForm />
                  </div>
                </>
              }
            </div>
          </div>
          <SaveBanner visible={showSavePrompt} onClick={handleSaveClick} />
        </main>
        {
          showImportModal &&
          <ImportConfigModal
            onDismiss={() => setShowImportModal(false)}
            onSave={handleSaveImportClick}
          />
        }
        <MailSentMessage isActive={showMailSentMessage} />
      </StateContext.Provider>
    </SettingsContext.Provider>
  );
}
