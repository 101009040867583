import React, { useContext } from 'react';

// Context
import { SettingsContext } from '../reducer';

// Components
import FormInput from '../components/FormInput';
import FormFile from '../components/FormFile';

// Constants
import { SETTINGS } from '../constants';

export default ({ title, onSaveClick, onImportClick, onSendClick, onResetClick, isSaving }) => {

    const dispatch = useContext(SettingsContext);

    return (
        <div className="sidebar">
            <h2 className="subtitle is-size-4 has-text-white has-text-centered">
                Editing GUI for {title}
            </h2>
            <div className="buttons" style={{ justifyContent: 'center' }}>
                <button className={`button is-primary ${isSaving ? 'is-loading' : ''}`} onClick={onSaveClick}>
                    save changes
                </button>
                <button className="button is-light" onClick={onImportClick}>
                    import config
                </button>
                <button className="button is-warning" onClick={onResetClick}>
                    reset
                </button>
            </div>
            <hr />
            <FormInput
                labelText="Background colour"
                hasAddOns={true}
                addOnsText="choose colour"
                type="color"
                onChange={value => dispatch({ type: 'update', payload: { name: SETTINGS.BACKGROUND_COLOR, value } })}
            />
            <FormFile
                labelText="Background image"
                settingsName={SETTINGS.BACKGROUND_IMAGE}
                showClearButton={true}
                fileSizeLimit={800}
                errorMessage="Image must be smaller than 800 Kb"
            />
            <FormInput
                labelText="Background cover"
                type="checkbox"
                placeholder="Stretch the background image?"
                onChange={value => dispatch({ type: 'update', payload: { name: SETTINGS.BACKGROUND_COVER, value: value ? 'cover' : '' } })}
            />
            <FormInput
                labelText="Show dark intro text"
                type="checkbox"
                placeholder="Switch between light and dark text"
                onChange={value => dispatch({ type: 'update', payload: { name: SETTINGS.WELCOME_TEXT_COLOUR, value: !value ? '#fff' : '#222f3e' } })}
            />
            <FormInput
                labelText="Sign in header colour"
                hasAddOns={true}
                addOnsText="choose colour"
                type="color"
                onChange={value => dispatch({ type: 'update', payload: { name: SETTINGS.LOGIN_HEADER_BACKGROUND_COLOR, value } })}
            />
            <FormInput
                labelText="Button and icons colour"
                hasAddOns={true}
                addOnsText="choose colour"
                type="color"
                onChange={value => dispatch({ type: 'update', payload: { name: SETTINGS.LOGIN_BRAND_COLOR, value } })}
            />
            <hr />
            <button className="button is-primary is-fullwidth" onClick={onSendClick}>
                send config to IAM Cloud
            </button>
        </div>
    );
};