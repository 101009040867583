import React, { useState, useRef } from 'react';
import ImageEditorRc from 'react-cropper-image-editor';

// Styles
import 'cropperjs/dist/cropper.css';

// Utils
import { serialiseFileUpload } from '../utils/utils';


export default ({ defaultImagePath, defaultImageAlign, onChange, onImageAlign }) => {

    const alignClass = 'has-text-';
    const [showEditor, setShowEditor] = useState(false);
    const [showFileUpload, setShowFileUpload] = useState(false);
    const [previewImagePath, setPreviewImagePath] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [imageAlign, setImageAlign] = useState(`${alignClass}${defaultImageAlign}`);
    const fileRef = useRef(null);

    const _imageValidator = (encodedImg, callback) => {
        const image = new Image();

        image.src = encodedImg;
        image.onload = () => {
            callback(image.height < 500 && image.width < 500);
        };
    };

    const alignImage = alignment => {
        setImageAlign(`${alignClass}${alignment}`);

        if (onImageAlign) {
            onImageAlign(alignment);
        }
    }

    const handleImageClick = () => {
        fileRef.current.click();
    };

    const handleFileChange = evt => {

        if (evt.target && evt.target.files && evt.target.files.length) {
            const file = evt.target.files[0];

            serialiseFileUpload(file, _imageValidator)
                .then(encodedFile =>
                    _imageValidator(encodedFile,
                        imageIsValid => {
                            if (imageIsValid) {
                                setPreviewImagePath(encodedFile);
                                setShowFileUpload(false);
                                setShowEditor(true);
                            } else {
                                setErrorMessage('file size must be less than 300px x 300px');
                            }
                        }
                    )
                );
        }
    };

    const toggleShowUploade = () => {
        setShowFileUpload(!showFileUpload);
    };

    const toggleEditImage = () => {
        setShowFileUpload(false);
        setShowEditor(true);
    };

    const handleImageSave = encodedFile => {
        setPreviewImagePath(encodedFile);
        setShowFileUpload(false);
        setShowEditor(false);

        if (onChange) {
            onChange(encodedFile);
        }
    };

    return (
        <div className="preview-image">
            <div class="buttons has-addons preview-image-alignment">
                <button className="button is-info" title="align image left" onClick={() => alignImage('left')}>
                    <span className="icon is-small">
                        <i className="fas fa-align-left"></i>
                    </span>
                </button>
                <button className="button is-info" title="align image center" onClick={() => alignImage('center')}>
                    <span className="icon is-small">
                        <i className="fas fa-align-center"></i>
                    </span>
                </button>
                <button className="button is-info" title="align image right" onClick={() => alignImage('right')}>
                    <span className="icon is-small">
                        <i className="fas fa-align-right"></i>
                    </span>
                </button>
            </div>
            {
                !showFileUpload &&
                !showEditor &&
                <figure className={`${imageAlign} is-marginless`} onClick={toggleShowUploade}>
                    <img src={previewImagePath || defaultImagePath} alt="Preview of the uploaded file" />
                </figure>
            }
            {
                showFileUpload &&
                <div className="preview-image-upload">
                    <p className="is-flex">
                        <button className="button is-text has-text-info" onClick={handleImageClick}>select your image</button>
                        or <button className="button is-text" onClick={toggleEditImage}>edit your image</button>
                        or <button className="button is-text" onClick={toggleShowUploade}>cancel</button>
                    </p>
                    {
                        errorMessage &&
                        <p className="error">
                            <small>{errorMessage}</small>
                        </p>
                    }
                </div>
            }
            {
                showEditor &&
                <>
                    <ImageEditorRc
                        crossOrigin="true"
                        src={previewImagePath || defaultImagePath}
                        style={{}}
                        aspectRation={16 / 9}
                        className="image-editor"
                        guides={true}
                        rotatable={false}
                        imageName="headerImage.png"
                        saveImage={handleImageSave}
                        responseType="blob/base64"
                    />
                    <button className="button is-text js-cancel-edit-image is-pulled-right" onClick={() => setShowEditor(false)}>cancel</button>
                    <br />
                    <p>&nbsp;</p>
                </>
            }
            <input
                className="is-hidden"
                type="file"
                name="fileUpload"
                accept="image/*"
                onChange={handleFileChange}
                ref={fileRef}
            />
        </div>

    );
};