export const serialiseFileUpload = (file, readAsURL = true) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = e => {
            resolve(reader.result);
        };

        reader.onError = err => {
            reject(err);
        }

        if (readAsURL) {
            reader.readAsDataURL(file);
        } else {
            reader.readAsText(file, 'UTF8');
        }
    });
};

export const saveFile = (content, fileName, contentType) => {
    const a = document.createElement('a');
    const file = new Blob([content], { type: contentType });

    a.href = URL.createObjectURL(file);
    a.download = fileName;
    a.click();

    URL.revokeObjectURL(a.href);
};

export const storageUtils = {
    saveConfig: (key, config) => {
        const jsonConfigObject = JSON.stringify(config);
        localStorage.setItem(key, jsonConfigObject);
    },
    loadConfig: key => {
        const configFile = localStorage.getItem(key);

        try {
            const parsedConfig = JSON.parse(configFile);
            return parsedConfig;
        } catch (err) {
            return {};
        }
    },
    deleteConfig: key => {
        localStorage.removeItem(key);
    }
};

export const sendMail = (subject, body, callback) => {
    // fire's off a Zapier webhook
    const msg = {
        subject,
        body
    };
    fetch('https://hooks.zapier.com/hooks/catch/561378/o9g91z6/', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(msg)
    })
        .then(() => callback());
};