import React from 'react';

// Constants
import { SETTINGS } from './constants';


export const SettingsContext = React.createContext(null);
export const StateContext = React.createContext(null);

export const initialState = {
    //[SETTINGS.USERNAME]: 'username',
    //[SETTINGS.TENANCYID]: '12345',
    [SETTINGS.LOGIN_HEADER_TEXT]: 'Please sign in',
    //[SETTINGS.LOGIN_HEADER_BACKGROUND_COLOR]: 'form_backgroundColor',
    [SETTINGS.LOGIN_USERNAME_LABEL]: 'Email Address',
    [SETTINGS.LOGIN_PASSWORD_LABEL]: 'Password',
    [SETTINGS.LOGIN_BUTTON_TEXT]: 'Sign in',
    [SETTINGS.LOGIN_BRAND_COLOR]: '#00a8e0',
    [SETTINGS.BACKGROUND_COLOR]: 'hsl(195, 100%, 44%)',
    //[SETTINGS.BACKGROUND_IMAGE]: 'layout_backgroundImage',
    //[SETTINGS.BACKGROUND_COVER]: 'layout_backgroundSize',
    [SETTINGS.MAIN_LOGO]: './IAMCloud-white-large.png',
    [SETTINGS.MAIN_LOGO_ALIGN]: 'left',
    [SETTINGS.WELCOME_TEXT_TITLE]: 'Welcome, please sign in',
    [SETTINGS.WELCOME_TEXT_CONTENT]: 'This service is provided by IAM Cloud. If you are experiencing problems accessing this portal please contact your IT Helpdesk.',
    [SETTINGS.WELCOME_TEXT_COLOUR]: '#fff'
};

export const reducer = (state, action) => {
    switch (action.type) {
        case 'update':
            return {
                ...state,
                [action.payload.name]: action.payload.value
            };
        case 'delete':
            const stateCopy = { ...state };
            delete stateCopy[action.payload.name];
            return stateCopy;
        case 'init':
            return {
                ...state,
                ...action.payload
            };
        case 'reset':
            return { ...initialState };
        default:
            throw new Error();
    }
};