import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';

import { ToastContainer } from 'react-toastify';

ReactDOM.render(
  <React.StrictMode>
    <App />
    <ToastContainer
      position="bottom-right"
      autoClose={2500}
      hideProgressBar={true}
      newestOnTop
      closeOnClick
      rtl={false}
      pauseOnVisibilityChange
      draggable={false}
      pauseOnHover
      toastClassName='iamc-toast'
      bodyClassName='iamc-toast__body'
      className='iamc-toast-container'
    />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
