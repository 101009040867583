import React, { useContext } from 'react';

// Context
import { SettingsContext, StateContext } from '../reducer';

// Components
import FormInput from './FormInput';

// Constants
import { SETTINGS } from '../constants';

export default ({ onSaveUsernameClick }) => {

    const dispatch = useContext(SettingsContext);
    const appState = useContext(StateContext);

    return (
        <div className="column is-6 is-offset-3">
            <div className="box">
                <div className="content is-medium">
                    <h1 className="subtitle is-size-3">Welcome to the IAM Cloud GUI editor</h1>
                    <p>Please enter your tenancy's username below to get started</p>
                </div>
                <FormInput
                    labelText="Tenancy Username"
                    placeholder="e.g. iamcloud"
                    iconClass="fa-edit"
                    onChange={value => dispatch({ type: 'update', payload: { name: SETTINGS.USERNAME, value } })}
                />
                <FormInput
                    labelText="Tenancy Id"
                    placeholder="12345"
                    iconClass="fa-edit"
                    onChange={value => dispatch({ type: 'update', payload: { name: SETTINGS.TENANCYID, value } })}
                />
                <button
                    className="button is-primary is-medium is-fullwidth"
                    disabled={!appState[SETTINGS.USERNAME]}
                    onClick={onSaveUsernameClick}
                >
                    Get started
                </button>
            </div>
        </div>
    );
};